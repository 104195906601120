import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ali-pagination',
  templateUrl: './ali-pagination.component.html',
  styleUrls: ['./ali-pagination.component.scss']
})
export class AliPaginationComponent implements OnInit {

  @Input() dataSource;
  @Input() set1;
  @Input() set2;
  @Input() currentPage;
  @Input() totalPages;
  @Input() incPageData;
  @Input() recordperpage;
  @Input() search;
  @Input() searchTerm;
  @Output() filterByPageEve = new EventEmitter();
  @Output() filterByPageSizeEve = new EventEmitter();
  @Output() filterBySearchEve = new EventEmitter();
  recordPerPages: any = [6, 12, 18, 24, 30, 50, 500];
  constructor() {
  }

  ngOnInit(): void {
  }
  getFilterByPageSize(size): void {
    console.log(size,'9090')
    this.filterByPageSizeEve.emit(size);

  }
  getFilterByPage(page: number): void {
    this.filterByPageEve.emit(page);
  }
  getFilterBySearch(searchTerm: any): void {
    if (searchTerm) {
      this.filterBySearchEve.emit(searchTerm);
    }
  }
  getFilterBySearchReset(): any {
    this.filterBySearchEve.emit('');
  }

}
